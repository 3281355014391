<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <g clip-path="url(#clip0_581_3592)">
            <path
                d="M10.2305 17.9658H7.34793C6.86646 17.9658 6.47479 17.5742 6.47479 17.0927V10.591H4.79306C4.31158 10.591 3.91992 10.1992 3.91992 9.71784V6.93184C3.91992 6.45036 4.31158 6.0587 4.79306 6.0587H6.47479V4.66358C6.47479 3.28026 6.90916 2.10335 7.7308 1.26028C8.55615 0.413376 9.70958 -0.0341797 11.0664 -0.0341797L13.2648 -0.0306091C13.7454 -0.0297852 14.1364 0.361878 14.1364 0.842529V3.42926C14.1364 3.91074 13.7449 4.3024 13.2635 4.3024L11.7834 4.30295C11.332 4.30295 11.217 4.39345 11.1925 4.42119C11.1519 4.46719 11.1037 4.59725 11.1037 4.95636V6.05856H13.1523C13.3065 6.05856 13.4559 6.0966 13.5843 6.16829C13.8613 6.32306 14.0335 6.61571 14.0335 6.93198L14.0324 9.71797C14.0324 10.1992 13.6408 10.5908 13.1593 10.5908H11.1037V17.0927C11.1037 17.5742 10.7119 17.9658 10.2305 17.9658ZM7.53003 16.9106H10.0484V10.1186C10.0484 9.79708 10.31 9.5356 10.6313 9.5356H12.9772L12.9781 7.11394H10.6312C10.3098 7.11394 10.0484 6.85246 10.0484 6.53098V4.95636C10.0484 4.5441 10.0903 4.07526 10.4014 3.72287C10.7775 3.29688 11.37 3.24771 11.7831 3.24771L13.0811 3.24716V1.02435L11.0656 1.02106C8.88506 1.02106 7.53003 2.41687 7.53003 4.66358V6.53098C7.53003 6.85233 7.26855 7.11394 6.9472 7.11394H4.97516V9.5356H6.9472C7.26855 9.5356 7.53003 9.79708 7.53003 10.1186V16.9106Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_581_3592">
                <rect width="17.9648" height="18" fill="white" transform="translate(0.0351562 0.000976562)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style></style>
